<template>
    <div class="p-2 d-flex justify-content-center align-items-center w-100">
        <div class="adfinity_block_13450"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions(['ADB_BLOCK_MOUNTED'])
    },
    mounted: function(){
        this.ADB_BLOCK_MOUNTED('imageArea')
    }
}
</script>
