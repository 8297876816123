<template>
    <div></div>
</template>

<script>

import utils from '@/functions/functions.js'
import { mapGetters } from 'vuex'
export default {
    props: ['backTo'],
    computed: {
        ...mapGetters(['OS', 'PLATFORM']),
       goalName: function(){
            return (this.backTo && this.backTo.match(/showsclick/i)) ? 'toAdwile' : 'toDeep'
       }
    },
    created: function(){
        const vueContext = this
        window.setTimeout(function(){
            try{
                history.pushState({},"","");
                window.onpopstate=()=>{
                        utils.safeGoal('backClick', vueContext)
                        utils.safeGoal(vueContext.goalName, vueContext)
                        location.replace(vueContext.backTo)
                }
                }catch(e){
                    console.log('e', e)
            }
        }, 500)
    }    

}
</script>
