<template>
  <div class="mt-3 position-relative">
    <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
    <div class="app-custom-slider-wrap d-flex overflow-hidden" id="slider-wrap">
      <!-- <transition name="fade-append"> -->
        <div v-if="contentReady" :class="wrapClassName" :style="sliderContentMargin">
          <CarouselBlockItem
            v-for="(item, index) in blockItems"
            v-bind:key="index"
            v-bind:index="index"
            v-bind:item="item"
            v-bind:aspect="aspect"
            v-bind:siteContent="siteContent"
            v-bind:heightControl="heightControl"
            v-bind:wopenTrigger="wopenTrigger"
            v-on:itemClick="itemClick"
            v-on:itemsMounted="itemsMounted"
          ></CarouselBlockItem>
        </div>
      <!-- </transition> -->
      <div v-if="!contentReady" :class="wrapClassName">
        <CarouselBlockPreloader
          v-for="(item, index) in preloaderItems"
          v-bind:key="index"
          v-bind:aspect="aspect"
        ></CarouselBlockPreloader>
      </div>
    </div>
    <!-- контролы -->
    <transition name="fade">
      <div v-if="sliderConrtols" class="app-scroll-control__left position-absolute align-items-center">
        <b-button variant="dark" :disabled="controlLeftDisable" @click="leftControl" class="rounded-circle">
          <b-icon icon="arrow-left" aria-hidden="true"></b-icon>
        </b-button>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="sliderConrtols" class="app-scroll-control__right position-absolute align-items-center">
        <b-button variant="dark" :disabled="sliderEnd"  @click="rightControl" class="rounded-circle">
          <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
        </b-button>
      </div>
    </transition>
  </div>
</template>


<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import CarouselBlockItem from './CarouselBlockItem'
import CarouselBlockPreloader from './CarouselBlockPreloader'
import utils from '../../functions/functions'

export default {
  components: {
    BButton,
    CarouselBlockItem,
    CarouselBlockPreloader    
  },  
  data(){
    return {
      offsetX: 0, //отрицательный margin-left
      feedLength: 0,
      wrapLength: 0,
      slideWidth: 0,
      sliderEnd: false,
      realOffset: 0,
      blockItems: [],
      //здесь заранее знаем
      preloaderItems: ['', '', '', '', ''],
      controlShow: false
    }
  },
  props: ['goalName', 'limit', 'dataFeed', 'heightControl', 'wopenTrigger', 'bg'],
  computed: {
    ...mapGetters(['SCREEN_SIZE', 'REFRESHING']),
    wrapClassName: function(){
      const classBase = 'app-slider-content d-flex'
      const bg = (this.bg) ? `bg-${this.bg}` : ''
      return `${classBase} ${bg}`
    },
    siteContent: function(){
      return (this.dataFeed === 'site') ? true : false
    },
    aspect: function(){
        //return (this.siteContent) ? '16:9' : '1:1'
        return '1:1'
    },    
    slidePerPage: function(){
      return (this.SCREEN_SIZE >=5) ? 5 : 4 // ПЕРЕДЕЛАТЬ ПОД КАЖДОЕ ЗНАЧЕНИЕ ШИРИНЫ
    },
    //показывать или нет контролы в зависимости от ширины экрана
    sliderConrtols: function(){
      return (this.controlShow && this.SCREEN_SIZE >= 6 && !this.REFRESHING) ? true: false
    },
    //левый контрол дизейбл
    controlLeftDisable: function(){
      return (!this.offsetX) ? true : false
    },
    //правый контрол дизейбл
    controlRightDisable: function(){
      return (this.sliderEnd) ? true : false
    },
    sliderContentMargin: function(){
      return `margin-left:-${this.offsetX}px`
    },
    contentReady: function(){
      return (this.blockItems.length && !this.REFRESHING) ? true : false
    }
  },
  methods: {
    arrayClone: function(arr){
        this.blockItems = []
        const shuffled = utils.shuffleArray(arr) 
        const limit = (this.limit) ? this.limit : arr.length           
        this.blockItems = shuffled.slice(0, limit)
    },
    itemClick: function(){
      const vueContext = this
      utils.safeGoal(this.goalName, vueContext)
      utils.safeGoal('GoLink', vueContext)
    },
    sliderInit: function(){
      let sliderContentWidth = 0
      const slides = document.getElementsByClassName('app-slide')
      this.slideWidth = slides[0].clientWidth + 20
      for (let i=0; i<slides.length; i++){
        sliderContentWidth = sliderContentWidth + this.slideWidth
      }
      this.feedLength = sliderContentWidth
      this.wrapLength = document.getElementById('slider-wrap').offsetWidth
    },
    sliderReset: function(){
      this.offsetX = 0
      this.feedLength = 0
      this.wrapLength = 0
      this.slideWidth = 0
      this.sliderEnd = false
      this.realOffset = 0      
    },
    rightControl: function(){
      const sum = this.offsetX + this.slidePerPage*this.slideWidth
      //заранее чекаем следующую перемотку
      const sumNext = this.offsetX + this.slidePerPage*this.slideWidth*2
      //def должен быть больше, чем this.wrapLength
      //если меньше, то this.offsetX вычисляется по другой формуле:  Ширина обертки - Общая ширина фида
      const def = this.feedLength - sum
      //пока сумма не превышает длину слайдер-контента, присваиваем сумму, иначе оставляем прошлое значение
      this.offsetX = (sum <= this.feedLength) ? sum : this.offsetX
      //сначала запоминаем реальное смещение, от которого потом будем минусовать
      this.realOffset = (def <= this.wrapLength) ? this.offsetX : 0
      //воизбежание пустых отступов справа
      this.offsetX = (def <= this.wrapLength) ? (this.feedLength - this.wrapLength) : this.offsetX
      //дизейблим кнопку
      this.sliderEnd = (sumNext >= this.feedLength) ? true : false
    },
    leftControl: function(){
      //если есть realOffset - минусуем от него
      const sum = (!this.realOffset) ? (this.offsetX - this.slidePerPage*this.slideWidth) : (this.realOffset - this.slidePerPage*this.slideWidth)
      //обнуляем this.realOffset
      this.realOffset = 0
      //заранее чекаем следующую перемотку
      const sumNext = this.offsetX + this.slidePerPage*this.slideWidth*2      
      this.offsetX = (sum >= 0) ? sum : this.offsetX
      //раздизейбливаем кнопку
      this.sliderEnd = false
    },
    itemsMounted: function(payload){
      if (payload === this.blockItems.length-1){
        this.controlShow = true
        // this.sliderReset()
        this.sliderInit()
      }      
    }
  },
  created: function(){
    const vueContext = this
    window.addEventListener('resize', function(){
      vueContext.sliderReset()
      vueContext.sliderInit()
    })    
  },
  watch: {
    blockItems: function(){
      //Это странная штука и похоже не работает!!!!!!!!!!!!!!!!!
      //сбрасываем только если роут-нэйм не равно home
      if (this.$route.name != 'home'){
        this.sliderReset()
      }
    },
    REFRESHING: function(){
      if (this.REFRESHING){
        this.sliderReset()
        this.blockItems = []
      }
    }
  }
}
</script>

<style lang="sass">
.app-slider-content
  overflow-x: scroll
  transition: all 0.6s
  scrollbar-width: none

.app-slider__scrollbar-hide
  margin-top: -16px
  height: 16px

.app-scroll-control__left
  width: 57px
  top: 0
  bottom: 0
  left: -78px
  display: none
.app-scroll-control__right
  width: 57px
  top: 0
  bottom: 0
  right: -78px
  display: none  
@media (min-width: 1360px)
  .app-scroll-control__left,
  .app-scroll-control__right
    display: flex
    button
      height: 57px  
</style>