<template>
    <div>
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <div v-if="SCREEN_SIZE > 2">
            <ToastBlockItem v-for="(item, index) in blockItems"
                v-bind:key="index"
                v-bind:index="index"
                v-bind:idBase="idBase"            
                v-bind:item="item"
                v-bind:goalName="goalName"
                v-bind:siteContent="siteContent"
                v-bind:wopenTrigger="wopenTrigger"
                v-on:itemsMounted="itemsMounted"
            ></ToastBlockItem>
        </div>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapGetters } from 'vuex'
import ToastBlockItem from './ToastBlockItem.vue'
import {BToast} from 'bootstrap-vue'
export default {
    components: {
        BToast,
        ToastBlockItem
    },
    data(){
        return {
            idBase: 'toast-',
            blockItems: []
        }
    },
    props: ['dataFeed', 'limit', 'goalName', 'timeInterval', 'wopenTrigger'],
    computed: {
        ...mapGetters(['SCREEN_SIZE', 'REFRESHING']),
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        }
    },
    methods: {
        arrayClone: function(arr){
            if (!this.blockItems.length){
                this.blockItems = []
                const shuffled = utils.shuffleArray(arr)            
                this.blockItems = shuffled.slice(0, this.limit)
            }
        },      
        makeToast: function(){
            let vueContext = this
            let counter = utils.closureIncrement()
            let pushing = window.setInterval(function(){
                let pushNum = counter()
                const toastId = vueContext.idBase+pushNum
                vueContext.$bvToast.show(toastId)
                if (pushNum===vueContext.limit){
                    clearInterval(pushing)
                }
            }, vueContext.timeInterval)        
        },
        itemsMounted: function(payload){
            if (payload === this.blockItems.length-1){
                this.makeToast()
            }
        },
    },
}
</script>
