import Vue from 'vue'
import Vuex from 'vuex'
import fullscreen from '../modules/fullscreenState'
import content from '../modules/content'
import integrations from '../modules/integrations'
import screen from '../modules/screen'
import devices from '../modules/devices'
import flow from '../modules/flow'
import routeTracking from '../modules/routeTracking'
import fraudDetect from '../modules/fraudDetect'
import tab from '../modules/tab'
import firebase from '../modules/firebase'
import rtb from '@/modules/rtb'
import windControl from '@/modules/windControl'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    fullscreen,
    content,
    integrations,
    screen,
    devices,
    flow,
    routeTracking,
    fraudDetect,
    tab,
    firebase,
    rtb,
    windControl
  }
})
