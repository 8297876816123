<template>
    <div id="adfinity"></div>
</template>

<script>
import { mapActions } from 'vuex';
import utils from '@/functions/functions.js'
export default {
    methods: {
        ...mapActions(['ADF_LOADED']),
        adfOverlay: function(){
            const s = document.createElement('script')
            s.async = true
            s.src = 'https://cdn.adfinity.pro/code/newsonline.press/adfinity.js'
            s.onload = this.adfLoad()
            s.onerror = this.adfError()
            document.getElementById('adfinity').appendChild(s)
        },
        adfLoad: function(){
            utils.safeGoal('adfLoaded', this)
            this.ADF_LOADED()
        },
        adfError: function(){
            utils.safeGoal('adfError', this)
        }
    },
    mounted: function(){
        this.adfOverlay()
    }
}
</script>
