<template>
    <div class="mt-5 mt-sm-0">
        <WindowControl></WindowControl>
        <bClick v-bind:backTo="backTo"></bClick>
        <TechnicalProps></TechnicalProps>
        <!-- слайдер -->
        <section class="bg-light d-flex justify-content-center align-items-center pt-2">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="'mixed'"
                            class="mb-3"
                            v-bind:wopenTrigger="false"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- текстовый блок -->
        <section class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlock'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="10"
                            v-bind:wopenTrigger="false"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Бесконечный Оверлей -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                            v-bind:wopenTrigger="false"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import TextBlock from '../components/integrations/TextBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import TechnicalProps from '../components/general/TechnicalProps'
import bClick from '@/components/flow/bClick.vue'
import WindowControl from '@/components/flow/WindowControl.vue'
import utils from '@/functions/functions.js'

export default {
    data(){
        return {
            hasRef: (utils.isDevelopment) ? true : utils.hasReferrer(),
        }
    },
    components: {
        BContainer, 
        BRow, 
        BCol,
        CarouselBlock,
        SimpleBlock,
        TextBlock,
        OverlayBlock,
        TechnicalProps,
        bClick,
        WindowControl
    },
    computed: {
        ...mapGetters(['OS', 'PLATFORM']),
        backTo: function(){
            const urlBase = (utils.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
            const page = (this.firstLevel) ? 'digest' : 'today'
            // const external = 'https://showsclick.com/widget.html?block_id=32843'
            const external = 'https://showsclicks.com/widget.html?block_id=32843'
            // return (this.OS != 'Windows' && this.PLATFORM != 'desktop') ? external : `${urlBase}/${page}`
            return (this.hasRef) ? external : `${urlBase}/${page}`
        },
        firstLevel: function(){
            return (document.location.pathname.match(/\/pwa2|\/today/i)) ? true : false
        },
        fromMain: function(){
            return (document.referrer && document.referrer.match(/\/post/))
        },
        fromDeep: function(){
            return (document.referrer && document.referrer.match(/\/pwa1|\/pwa2\/|\/today|\/digest/i)) ? true : false
        },
        fromTopen: function(){
            return (document.referrer && document.referrer.match(/\/newzFeed/i)) ? true : false
        },
        wopenTrigger: function(){
            return (this.PLATFORM === 'desktop') ? true : false
        }       
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_INFOX', 'SET_GLOBAL_CLICK']),
    },
    created: function(){
        if (this.wopenTrigger) this.SET_GLOBAL_CLICK()
        this.FEED_COUNT_RESET()
        this.FEED_SUM_DEFINE(2)
        // this.FETCH_SMI24(this)
        this.SMI2_FETCH(this)
        this.FETCH_INFOX(this)
        if (this.fromMain) utils.safeGoal('fromMain', this)
        if (this.fromDeep) utils.safeGoal('fromDeep', this)
        if (this.fromTopen) utils.safeGoal('fromTopen', this)
        utils.safeGoal('TEST', this)
    },
}
</script>

<style lang="sass">

</style>