<template>
    <div>
        <TechnicalProps></TechnicalProps>
        <WindowControl></WindowControl>
        <bClick v-bind:backTo="backTo"></bClick>
        <!-- слайдер -->
        <section class="bg-white d-flex justify-content-center align-items-center pt-2">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="'mixed'"
                            class="mb-3"
                            v-bind:wopenTrigger="false"
                            v-bind:bg="'white'"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- текстовый блок -->
        <section class="py-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlock'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="10"
                            v-bind:wopenTrigger="false"
                            v-bind:icon="'lightning'"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Бесконечный Оверлей -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                            v-bind:wopenTrigger="false"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import TextBlock from '../components/integrations/TextBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import TechnicalProps from '../components/general/TechnicalProps'
import bClick from '@/components/flow/bClick.vue'
import utils from '@/functions/functions.js'
import WindowControl from '@/components/flow/WindowControl.vue'


export default {
    data(){
        return {
            flowDone: undefined
        }
    },
    components: {
        CarouselBlock,
        SimpleBlock,
        TextBlock,
        OverlayBlock,
        TechnicalProps,
        bClick,
        WindowControl
    },
    computed: {
        ...mapGetters(['OS', 'PLATFORM']),
        backTo: function(){
            const urlBase = (utils.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
            return `${urlBase}/today`
        },
        wopenTrigger: function(){
            return (this.PLATFORM === 'desktop') ? true : false
        }       
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_INFOX', 'SET_GLOBAL_CLICK']),
        teaserPool: function(){
            this.FEED_COUNT_RESET()
            this.FEED_SUM_DEFINE(2)
            // this.FETCH_SMI24(this)
            this.SMI2_FETCH(this)
            this.FETCH_INFOX(this)
        }
    },
    created: function(){
        if (this.wopenTrigger) this.SET_GLOBAL_CLICK()
        this.teaserPool()
        utils.safeGoal('TEST', this)
    },
}
</script>

<style lang="sass">

</style>