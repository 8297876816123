import { render, staticRenderFns } from "./DemoImage.vue?vue&type=template&id=69008909&scoped=true"
var script = {}
import style0 from "./DemoImage.vue?vue&type=style&index=0&id=69008909&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69008909",
  null
  
)

export default component.exports