<template>
    <div>
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <!-- <transition name="fade-append"> -->
            <div v-if="contentCeady" :class="wrapClassName">
                <TextBlockItem v-for="(item, index) in blockItems"
                    v-bind:key = index
                    v-bind:currentIndex="index"
                    v-bind:siteContent="siteContent"
                    v-bind:maxIndex = limit
                    v-bind:fontWeight="fontWeight"
                    v-bind:borderColor="borderColor"
                    v-bind:item="item"
                    v-bind:icon="icon"
                    v-bind:wopenTrigger="wopenTrigger"
                    v-on:itemClick="itemClick"    
                ></TextBlockItem>                 
            </div>
        <!-- </transition> -->

        <div v-if="!contentCeady" :class="wrapClassName">
            <TextBlockPreloader v-for="(item, index) in preloaderItems"
                v-bind:key="index"
            ></TextBlockPreloader>
        </div>        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
import TextBlockItem from './TextBlockItem'
import TextBlockPreloader from './TextBlockPreloader'

export default {
    components: {
        TextBlockItem,
        TextBlockPreloader
    },
    data(){
        return{
            blockItems: [],
            //будем вычислять в зависимости от limit
            preloaderItems: [],
            wrapClassName: 'd-flex flex-column',
        }
    },    
    props: ['limit', 'goalName', 'dataFeed', 'fontWeight', 'borderColor', 'icon', 'wopenTrigger'],    
    computed: {
        ...mapGetters(['REFRESHING']),
        contentCeady: function(){
            return (this.blockItems.length && !this.REFRESHING) ? true : false
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },
    },
    methods: {
        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        },
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)            
            const limit = (this.limit) ? this.limit : arr.length
            this.blockItems = shuffled.slice(0, limit)
        },
    },
    created: function(){
        for (let i=0; i<this.limit; i++){
            this.preloaderItems.push('')
        }
    },
    watch: {
        REFRESHING: function(){
            if (this.REFRESHING){
                this.blockItems = []
            }
        }
    }
}
</script>
