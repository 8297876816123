import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sass/main.sass'

import utils from './functions/functions'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


//GetContent регаем глобально
Vue.component('GetContent', () => import('./components/general/GetContent'))
Vue.component('GetLucky', () => import('./components/general/GetLucky'))
Vue.component('ScreenModal', () => import('./components/flow/ScreenModal'))

import './css/chrome-style.css'    
Vue.config.productionTip = false


import VueYandexMetrika from 'vue-yandex-metrika'   
// if (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK){
  let metrikaId = process.env.VUE_APP_METRIKA_MAIN
  if(document.location.href.match(/\/(app|pwa3|pwa)/i))    metrikaId = process.env.VUE_APP_METRIKA_PWA
  if(document.location.href.match(/\/pwa1/i))    metrikaId = process.env.VUE_APP_METRIKA_PWA1
  if(document.location.href.match(/\/digest/i))    metrikaId = process.env.VUE_APP_METRIKA_DEEP
  if(document.location.href.match(/\/pwa2/i))    metrikaId = process.env.VUE_APP_METRIKA_5
  if(document.location.href.match(/\/today/i))    metrikaId = process.env.VUE_APP_METRIKA_BACK
  if (document.location.href.match(/\/allNews/i)) metrikaId = process.env.VUE_APP_METRIKA_WOPEN
  if (document.location.href.match(/\/newzFeed/i)) metrikaId = process.env.VUE_APP_METRIKA_TOPEN
  // if(document.location.href.match(/\/to/i))    metrikaId = process.env.VUE_APP_METRIKA_TRANSFER 
  // utils.consoleLog('Metrika ID in main.js', metrikaId)
 
  Vue.use(VueYandexMetrika, {
    id: metrikaId,
    router: router,
    // env: (process.env.NODE_ENV === 'development') ? 'development' : 'production',
    // env: (document.location.origin+'/' === process.env.VUE_APP_HOST_NAME_LOCK) ? 'production' : 'development',
    // env: 'production',
    env: (!utils.isDevelopment) ? 'production' : 'development',
    // triggerEvent: true, //????????????????????????????????????????
    options: {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true,
      // triggerEvent: true  //????????????????????????????????????????
    }
  })
 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
