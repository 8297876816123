<template>
    <div class="d-flex justify-content-center w-100 my-3">
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <b-link v-if="contentReady" 
            :href="linkHref" 
            :to="linkTo" 
            :target="target" 
            :rel="rel"
            @click="itemClick"
            class="d-flex justify-content-end align-items-stretch text-decoration-none rounded border bg-light p-2"
        >
            <div class="d-flex flex-column justify-content-between">
                <div class="d-flex flex-column justify-content-between flex-grow-1">
                    <h5 class="text-dark">
                        {{ item.title }}
                    </h5>
                    <p class="d-none d-md-block text-secondary font-weight-light font-italic">
                        Читайте больше подробностей на сайте {{ item.name }}
                    </p>
                </div>
                <div class="d-none d-md-flex border-top pt-2">
                    <b-button pill size="sm" variant="outline-danger" class="mr-3 font-weight-bold px-3">Перейти к новости</b-button>
                    <b-button pill size="sm" variant="outline-primary" class="font-weight-bold px-3">Читать на сайте</b-button>
                </div>    
            </div>
            <b-aspect :aspect="aspect" class="app-skeleton-wrap overflow-hidden rounded flex-shrink-0 position-relative ml-3 ml-xl-5 align-self-center position-relative">
                <b-skeleton height="100%" width="100%"></b-skeleton>
                <div class="position-absolute d-flex justify-content-center align-items-center" style="top:0; bottom:0; left:0; right:0;">
                    <b-img-lazy :src="imageSrc"></b-img-lazy>
                </div>            
            </b-aspect>
        </b-link>
        <div v-else class="app-jumbtron-skeleton rounded overflow-hidden w-100">
            <b-skeleton width="100%" height="100%"></b-skeleton>
        </div>
    </div>
</template>

<script>
import { BLink, BSkeleton, BAspect, BButton, BImgLazy } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
export default {
    components:{
        BLink, 
        BSkeleton, 
        BAspect, 
        BButton, 
        BImgLazy
    },
    data(){
        return {
            item: undefined,
        }
    },
    props: ['dataFeed', 'goalName', 'wopenTrigger'],
    computed: {
        ...mapGetters(['SMI24', 'INFOX', 'POSTS', 'MIXED', 'SMI2', 'REFRESHING']),
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (!this.siteContent) ? utils.getLink(this.item.url, this) : null
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        rel: function(){
            return (this.wopenTrigger) ? '' : 'noopener'
        },
        sourceName: function(){
            return (this.item.name) ? this.item.name : 'newsonline.press'
        },
        aspect: function(){
            let aspect = '1:1'
            if (this.siteContent){
                aspect = '16:9'
            }else if (this.item && this.item.img.match(/smi2\.net/i)){
                aspect = '4:3'
            }
            return aspect
        },
        imageSrc: function(){
            return this.item.img.replaceAll('100_100', '400_400')
        },
        contentReady: function(){
            return (this.item && !this.REFRESHING) ? true : false
        },                
    },
    methods: {
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            this.item = shuffled[0]
        },
        itemClick: function(){
            utils.safeGoal(this.goalName, this)
            utils.safeGoal('GoLink', this)
        }
    },
    watch: {
        REFRESHING: function(){
            if (this.REFRESHING) this.item = undefined
        }
    }
}
</script>

<style lang="sass">
.app-skeleton-wrap
    width: 100px
.app-jumbtron-skeleton    
    height: 122px
@media (min-width: 768px)        
    .app-skeleton-wrap
        width: 140px
    .app-jumbtron-skeleton    
        height: 191px        
@media (min-width: 992px)        
    .app-skeleton-wrap
        width: 200px
    .app-jumbtron-skeleton    
        height: 222px        
</style>

<style lang="sass" scoped>
img
    width: 100%
    height: auto
h5
    font-size: 1.1rem
@media (min-width: 768px)    
    h5
        font-size: 1.25rem
@media (min-width: 992px)                
    h5
        font-size: 1.8rem   
//      122 191 222   
</style>