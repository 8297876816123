<template>
    <div>
        <TechnicalProps></TechnicalProps>
        <!-- слайдер -->
        <section class="bg-white d-flex justify-content-center align-items-center pt-2">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="'mixed'"
                            class="mb-3"
                            v-bind:wopenTrigger="false"
                            v-bind:bg="'white'"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- текстовый блок -->
        <section class="py-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlock'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="10"
                            v-bind:wopenTrigger="false"
                            v-bind:icon="'lightning'"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Бесконечный Оверлей -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                            v-bind:wopenTrigger="false"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import TextBlock from '../components/integrations/TextBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import TechnicalProps from '../components/general/TechnicalProps'
import utils from '@/functions/functions.js'

export default {
    data(){
        return {
            flowDone: undefined
        }
    },
    components: {
        BContainer, 
        BRow, 
        BCol,
        CarouselBlock,
        SimpleBlock,
        TextBlock,
        OverlayBlock,
        TechnicalProps
    },
    computed: {
        ...mapGetters(['OS', 'PLATFORM', 'MIXED_READY']),
        isOpen: function(){
            return (!document.location.href.match(/collapsed=1/i) || this.flowDone) ? true : false
        },
        fromMain: function(){
            return (document.referrer && document.referrer.match(/\/post/i)) ? true : false
        },
        fromBack: function(){
            return (document.referrer && document.referrer.match(/\/pwa2|\/pwa1|\/today|\/digest/i)) ? true : false
        },
        fromTopen: function(){
            return (document.referrer && document.referrer.match(/\/newzFeed/i)) ? true : false
        },
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_INFOX']),
        toShow: function(){
            if (!this.isOpen){
                this.$router.push(`/allNews`)
                this.teaserPool()
                this.flowDone = true
            }
        },
        windowResize: function(){
            window.moveTo(0, 0);
            window.resizeTo(screen.width, screen.height)
            if (!this.isOpen) utils.safeGoal('windowResize', this)
            

        },
        teaserPool: function(){
            this.FEED_COUNT_RESET()
            this.FEED_SUM_DEFINE(2)
            // this.FETCH_SMI24(this)
            this.SMI2_FETCH(this)
            this.FETCH_INFOX(this)
        }
    },
    created: function(){
        utils.safeGoal(((this.isOpen) ? 'startOpened' : 'startCollapsed'), this)
        if (this.fromMain) utils.safeGoal('fromMain', this)
        if (this.fromBack) utils.safeGoal('fromBack', this)
        if (this.fromTopen) utils.safeGoal('fromTopen', this)
        // utils.safeGoal('loadOutright', this)
        // utils.safeGoal('designUpd1', this)
        if (document.hidden) this.windowResize()
        if (this.isOpen) this.teaserPool()
        const vueContext = this        
        window.addEventListener('blur', function(){
            vueContext.windowResize()
            
        })
        window.addEventListener('focus', function(){
            vueContext.toShow()
        })        
    },
    watch: {
        flowDone: function(){
            utils.safeGoal('flowDone', this)
        },
        isOpen: function(){
            // console.log('this.isOpen', this.isOpen)
        },
        MIXED_READY: function(){
            utils.safeGoal('mixedReady', this)
        }
    }
}
</script>

<style lang="sass">

</style>