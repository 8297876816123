<template>
    <b-link
        class="app-slide d-block position-relative rounded overflow-hidden pb-5 flex-shrink-0 mr-3" 
        :style="styleParent"
        :to="linkTo"
        :href="linkHref"
        :target="target"
        :id="uniqId"
        @click="itemClick"
    >
        <b-aspect :aspect="aspect" class="position-relative">
            <div class="app-slide__image-wrap d-flex justify-content-center align-items-start position-absolute">
                <!-- транзишн не работает из за лейзи. лейзи нельзя убрать, иначе будет большая линейная нагрузка -->
                <b-img-lazy :src="imageSrc"></b-img-lazy>
            </div>
        </b-aspect>
        <div class="position-absolute d-flex flex-column justify-content-end align-itemc-start pb-2 px-2" style="top:0;left:0;right:0;bottom:0;">
            <div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
            </div>
            <div :class="classTitle" :style="styleTitle">
                {{item.title }}
            </div>
            <div v-if="showSiteName" class="app-slide-name position-relative align-self-start rounded-pill text-white px-3 py-0 bg-dark">
                {{ item.name }}
            </div>
            <b-badge v-if="showBadge" pill variant="success" class="position-relative align-self-start">Читать с рекламой</b-badge>
        </div>
    </b-link>
</template>


<script>
import utils from '../../functions/functions'
import {FastAverageColor} from 'fast-average-color'
import {BAspect, BImgLazy, BLink, BBadge} from 'bootstrap-vue'
export default {
    components: {
        BAspect,
        BImgLazy,
        BLink, 
        BBadge
    },
    data(){
        return{
            mainColorRgb: '255, 255, 255',
            uniqId: utils.uuidv4()
        }
    },
    props: ['item', 'index', 'aspect', 'siteContent', 'wopenTrigger'],
    computed: {
        classTitle: function(){
            const classBase = 'app-slide-title position-relative mb-2 font-weight-bold'
            const textModificator = (this.mainColorRgb === '255, 255, 255') ? '' : 'text-white'
            return `${classBase} ${textModificator}`
        }, 
        styleTitle: function(){
            return (this.mainColorRgb === '255, 255, 255') ? 'color: black; text-shadow: rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px;' : 'text-shadow: 0 0 4px #000000;'
        },
        imageSrc: function(){
            return this.item.img.replaceAll('100_100', '400_400')
        },
        styleParent: function(){
            return `background:rgb(${this.mainColorRgb})`
        },        
        styleColorOverlay: function(){
            const radialGradient = `background:radial-gradient(100% 100% at 50% 0px, transparent 50%, rgb(${this.mainColorRgb}) 100%) rgba(0, 0, 0, 0.2)`
            // return (this.mainColorRgb) ? radialGradient : null
            return radialGradient
        },
        id: function(){
            return `app-slider-link-${this.index}`
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : utils.getLink(this.item.url, this)
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        showSiteName: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) && this.item.name && !this.item.url.match(/smi2\./i)) ? true : false
        },
        showBadge: function(){
            const vueContext = this
            // return (utils.isRotator(vueContext) && this.item.url.match(/smi2\./i)) ? true : false
            return false
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        },
    },
    created: function(){

    },
    beforeMount: function(){
        if (this.item.rgb){
            this.mainColorRgb = this.item.rgb
        }else{
            const imgPath = (this.siteContent) ? this.item.imgMicro : this.item.img
            const fac = new FastAverageColor()
            fac.getColorAsync(imgPath)
                .then(color => {
                    const colorString = color.rgb
                    const match = colorString.match(/([\d]+),([\d]+),([\d]+)/i)
                    this.mainColorRgb = `${match[1]},${match[2]},${match[3]}`
                })
                .catch(e => {
                    console.log('color define brocken')
                    console.log(e);
                }
            )
        }
    },
    mounted: function(){
        this.$emit('itemsMounted', this.index)
    },
    watch: {
        mainColorRgb: function(){
            // console.log('задетектили цвет')
        }
    }
}
</script>

<style lang="sass">
.app-slide__image-wrap
    top: 0
    bottom: 0
    left: 0
    right: 0
.app-slide
    width: 240px
    // height: 300px ////////////////////
.app-slide-name
    font-size: 0.7rem
    opacity: 0.5
.app-slide-overlay
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: transparent
    transition: all 1.15s
.app-slide-title    
    font-size: 1.2rem
    transition: all 0.15s
    // text-shadow: 0 0 4px #000000
</style>

<style lang="sass" scoped>
img
    height: 100%
    width: auto
</style>