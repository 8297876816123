<template>
    <div>
        <WindowControl></WindowControl>
        <TabControl></TabControl>
        <section>
            <ScreenModal
                v-bind:modalReady="modalReady" 
                v-bind:delayTime="delayTime"
                v-bind:targetEvent="targetEvent"
                v-on:firstClose="firstClose"
                v-on:secondClose="secondClose"
            ></ScreenModal>
            <!-- Блюрное затемнение не слетает при фокусе-расфокусе -->
            <!-- desktopOverlay -->

            <!-- <ScreenOverlay v-if="PWA_INSTALLER_STATE"></ScreenOverlay>             -->
        </section>        
    </div>
</template>

<script>
import utils from '../../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import ScreenOverlay from './ScreenOverlay'
import WindowControl from '@/components/flow/WindowControl.vue'
import TabControl from '@/components/flow/TabControl.vue'
export default {
    components: {
        ScreenOverlay,
        WindowControl,
        TabControl,
    },
    data(){
        return {
            beforeInstall: false,
            flowReady: false,
            desktopOverlay: false,
            pushPromptUnfired: undefined,
            hasRef: (utils.isDevelopment) ? true : utils.hasReferrer(),
            hardRef: (utils.isDevelopment) ? false : utils.hasReferrerHard(),
            tStart: false,
            twClosed: false,
            pwaStart: false,
            pwaClosed: false,
            topenTime: (utils.isDevelopment) ? 5000 : 10000,
            pwaTime: (utils.isDevelopment) ? 5000 : 45000,
            deferredPrompt: undefined
        }
    },
    computed: {
        ...mapGetters(['START_TIME', 'OS', 'PUSH_PROMPT_ACTIVE', 'INSTALLABILITY',
        'T_OPEN_STATE', 'STANDALONE', 'PLATFORM', 'DEFERRED_PROMPT', 'BROWSER', 'PWA_INSTALLER_STATE']),
        testPage: function(){
            // return (utils.getIdFromAddressBar() === '391530') //384809
            return false
        },
        modalReady: function(){
            /*
            const resultConditions = (!this.T_OPEN_STATE) ? this.tabContitions : ((this.INSTALLABILITY) ? this.pwaConditions : this.fullscreenConditions)
            // const resultConditions = (this.INSTALLABILITY) ? this.pwaConditions : this.fullscreenConditions
            return (resultConditions && !this.PUSH_PROMPT_ACTIVE) ? true : false
            */
           return false
        },
        tabContitions: function(){
            // return (utils.isDevelopment) ? this.tStart : (this.tStart && (this.hardRef || this.testPage) && !this.STANDALONE && !utils.fromPush)
            return this.tStart
        },
        pwaConditions: function(){
            return (this.beforeInstall && this.pwaStart && !this.STANDALONE && !utils.fromPush)
        },
        fullscreenConditions: function(){
            return (utils.fullScreenAbility() && this.hasRef)
        },
        delayTime: function(){
            const timeOut = (utils.isDevelopment) ? 1500 : 20000 
            return (this.pushPromptUnfired) ? timeOut : 0 
        },
        targetEvent: function(){
            return (!this.T_OPEN_STATE) ? 'tOpen' : ((this.INSTALLABILITY) ? 'pwa' : 'fullscreen')
        },
        wopenTrigger: function(){
            return (this.PLATFORM === 'desktop') ? true : false
        }
    },
    methods: {
        ...mapActions(['FLOW_READY_CHECK', 'SUBSCRIBE_SET', 'SET_GLOBAL_CLICK', 'GET_PROMPT', 'PWA_CLICK_SET']),
        twTimer: function(){
            const vueContext = this
            window.setTimeout(function(){
                vueContext.tStart = true
            }, vueContext.topenTime)
        },
        pwaTimer: function(){
            // console.log('pwaTimer')
            const vueContext = this
            window.setTimeout(function(){
                vueContext.pwaStart = true
            }, vueContext.pwaTime)
        },
        firstClose: function(){
            this.twClosed = true
        },
        secondClose: function(){
            this.pwaClosed = true
        },
        desktopBlur: function(){
            if (this.OS === 'Windows'){
                this.desktopOverlay = true
                // const htmlParentEl = document.getElementsByTagName('html')[0]
                // htmlParentEl.style.filter = 'blur(1px)'
            }
        },
        desktopUnBlur: function(){
            this.desktopOverlay = false
            // const htmlParentEl = document.getElementsByTagName('html')[0]
            // htmlParentEl.removeAttribute('style')
        },   
        pwaBind: function(){
            const vueContext = this
            const triggerElements = document.getElementsByClassName('app-install-trigger')
            for (let i=0; i<triggerElements.length; i++){
                const installTrigger = triggerElements[i]
                installTrigger.addEventListener('click', (e) => {
                    e.preventDefault()
                    try {
                        vueContext.desktopBlur()
                        vueContext.deferredPrompt.prompt()
                        utils.safeGoal('PromptShow', vueContext)
                        vueContext.deferredPrompt.userChoice.then((choiceResult) => {
                            vueContext.desktopUnBlur()
                            if (choiceResult.outcome === 'accepted') {
                                vueContext.SUBSCRIBE_SET()
                                utils.safeGoal('PromptAccept', vueContext)
                            }else{
                                utils.safeGoal('PromptDecline', vueContext)
                            }
                            vueContext.deferredPrompt = null;
                        });
                    } catch(e){
                        utils.safeGoal('ErrorInLogic', vueContext)
                    }
                })
            }
        }   
    },    
    created: function(){
        /*
        console.log('CREATED this.T_OPEN_STATE', this.T_OPEN_STATE)
        console.log('CREATED this.targetEvent', this.targetEvent)
        console.log('CREATED this.modalReady', this.modalReady)
        */
        if (this.wopenTrigger) this.SET_GLOBAL_CLICK()
        const vueContext = this
        window.addEventListener('focus', function(){
            vueContext.desktopUnBlur()
        })
        const a = (!this.T_OPEN_STATE) ? this.twTimer() : this.pwaTimer()
    },
    beforeMount: function(){
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.GET_PROMPT(e)
            /*
            this.deferredPrompt = e
            this.flowReady = true
            this.beforeInstall = (!this.beforeInstall) ? true : this.beforeInstall
            */
        })
    },
    watch: {
        PWA_INSTALLER_STATE: function(){
            // console.log('this.PWA_INSTALLER_STATE', this.PWA_INSTALLER_STATE)
        },
        DEFERRED_PROMPT: function(){
            if (!this.DEFERRED_PROMPT) return false
            this.PWA_CLICK_SET(this)
            // console.log('this.BROWSER', this.BROWSER)
        },
        targetEvent: function(){
            // console.log('this.targetEvent', this.targetEvent)
            // if (this.targetEvent === 'pwa') this.pwaBind()
        },
        deferredPrompt: function(newVal, oldVal){
            if (!newVal) return false
            this.pwaBind()
        },
        twClosed: function(){
            this.pwaTimer()
        },
        modalReady: function(){
            if (this.targetEvent === 'pwa') this.pwaBind()
        },
        flowReady: function(newVal, oldVal){
            const ready = (newVal && !oldVal)
            this.FLOW_READY_CHECK(ready)
        },
        PUSH_PROMPT_ACTIVE: function(newVal, oldVal){
            if (!newVal && oldVal){
                this.pushPromptUnfired = true    
            }
        },
    }
}
</script>