import utils from '@/functions/functions.js'
export default {
    state: {
        wFired: (localStorage.getItem('wOpen')) ? true : false,
        // wFired: true,
        // tFired: (localStorage.getItem('tOpen')) ? true : false,
        tFired: true,
        // wLink: undefined,
        tLink: undefined,
        winParams: `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=10,height=10,left=0,top=1000`
    },
    actions: {
        /*
        SET_GLOBAL_CLICK(context, tab=false){
            utils.consoleLog('SET_GLOBAL_CLICK action')
            document.addEventListener('click', function(e){
                let el = e.target
                let actionPinned = utils.pinnedCheck(el)
                utils.consoleLog('actionPinned', actionPinned)
                if (!actionPinned){
                    do {
                        el = utils.parentRetern(el)
                        actionPinned = (utils.pinnedCheck(el)) ? utils.pinnedCheck(el) : actionPinned
                    }while(el && el.nodeName && el.nodeName !== 'BODY')                    
                }
                if (!actionPinned) context.commit((tab) ? 'T_STATE_UPDATE' : 'W_STATE_UPDATE')   
            })
        },
        W_OPEN(context){
            context.commit('W_STATE_UPDATE')
        },
        */
        T_OPEN(context){
            context.commit('T_STATE_UPDATE')
        }
    },
    mutations: {
        /*
        W_STATE_UPDATE(state){
            utils.consoleLog('W_STATE_UPDATE mutation')
            if (!state.wFired){
                utils.consoleLog('ABOUT BLANK OPENING.....................')
                state.wLink = window.open('about:blank', null, state.winParams)
                state.wFired = true
                localStorage.setItem('wOpen', '1')
            }
        },
        */
        T_STATE_UPDATE(state){
            // || utils.isDevelopment
            if (!state.tFired){
                localStorage.setItem('tOpen', '1')
                localStorage.setItem('scrollHeight', window.pageYOffset)
                state.tFired = true
                state.tLink = window.open(document.location.href)    
            }
        }
    },
    getters: {
        /*
        W_OPEN_STATE(state){
            return state.wFired
        },
        W_LINK(state){
            return state.wLink
        },
        */
        TW_FIRED(state){
            return (state.wFired || state.tFired) ? true : false
        },
        T_OPEN_STATE(state){
            return state.tFired
        },
        T_LINK(state){
            return state.tLink
        }
    }
}