<template>
    <div></div>
</template>

<script>
import utils from '@/functions/functions.js'
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            locationChanged: false
        }
    },
    computed: {
        ...mapGetters(['T_LINK']),
        tUrl: function(){
            const urlBase = (utils.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
            return `${urlBase}/newzFeed`
        }
    },
    methods: {
        locationControl: function(){
            // console.log('locationControl')
            if (!this.locationChanged){
                this.locationChanged = true
                // let a = (utils.isDevelopment) ? utils.consoleLog(`to -> ${this.tUrl}`) : location.replace(this.tUrl)
                //location.replace(this.tUrl)
                this.$router.push('/newzFeed')
            }
        }
    },
    watch: {
        T_LINK: function(){
            utils.safeGoal('tOpen', this)
            const vueContext = this
            /*
            this.T_LINK.addEventListener('load', function(){
                utils.consoleLog('fired on load')
                vueContext.locationControl()
            })
            */
            this.T_LINK.addEventListener('focus', function(){
                utils.consoleLog('fired on focus')
                vueContext.locationControl()
            })
        },
    }
}
</script>
