<template>
    <div>
         <ScreenModal 
            v-bind:modalReady="modalReady" 
            v-bind:delayTime="0" 
            v-bind:targetEvent="'push'"
            v-bind:abTest="true"
        ></ScreenModal>
        <!-- <pushOnline></pushOnline> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
// import { getAnalytics } from "firebase/analytics"
import utils from '../../functions/functions.js'
import pushOnline from "./pushOnline.vue"
export default{
    components: {
        pushOnline
    },
    data(){
        return {
            backendActive: undefined, // (utils.isDevelopment) ? true : undefined,
            firebaseApp: undefined,
            getTokenStarted: undefined,
            permissionStatus: Notification.permission,
            modalReady: false,
        }
    },
    computed: {
        ...mapGetters(['FIREBASE_CONFIG', 'FIREBASE_TOKEN', 'FIREBASE_USERID', 'PUSH_PROMPT_ACTIVE', 'OS', 'BROWSER', 'PLATFORM']),
        testPage: function(){
            return (utils.getIdFromAddressBar() === '391530') //384809
        },
        lockerTrigger: function(){
            const isValidBrowser = (!this.BROWSER.match(/Firefox|Edge/i))
            const hasRef = (utils.isDevelopment || this.testPage) ? true : utils.hasReferrerHard()
            const defaultDevices = (this.OS === 'Windows' && isValidBrowser)
            //const chromeMobile = (this.BROWSER.match(/Chrome/i) && this.PLATFORM != 'desktop')
            return (this.PUSH_PROMPT_ACTIVE && defaultDevices && hasRef) ? true : false
        },
        isYandex: function(){
            return (this.BROWSER.match(/Yandex/i)) ? true : false
        }
    },
    methods: {
        ...mapActions(['TOKEN_SET', 'PUSH_PROMPT_FIRE', 'PUSH_PROMPT_UNFIRE']),
        backendPing: async function(){
            try {
                const ok = (await fetch(`${utils.backendHost}/observe`, {
                method: 'GET',
                headers: {
                    'Content-type' : 'application/json'
                },
                })).ok
                if (ok) this.backendActive = true
            }catch(e){
                // console.log('00000000000')
            }
        },
        sendToken: async function(token){
            if (this.FIREBASE_TOKEN === token) return false
            const res = await fetch(`${utils.backendHost}/addToken`, {
                method: 'POST',
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify({token: token}),
            })
            const result = await res.json()
            if (result.success && result.subscribed){
                const userData = {
                    userId: result.userId,
                    token: token
                }
                this.TOKEN_SET(userData)
            }
        },
        saveToken: async function(token){
            await fetch(`${utils.backendHost}/saveToken`, {
                method: 'POST',
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify({ token: token }),
            })
        },
    },
    beforeCreate: function(){
        navigator.serviceWorker.register("/firebase-messaging-sw.js", {scope: "/firebase-cloud-messaging-push-scope"}); //.then((reg) => {})

    },
    created: async function(){
        const vueContext = this
        document.addEventListener("readystatechange", function() {
            if (vueContext.getTokenStarted) {
                Notification.requestPermission()
                utils.safeGoal('tryRequest', vueContext)
            } 
        })

        this.backendPing()
        navigator.permissions.query({ name: "notifications" })
        .then((permissionStatus) => {
            // console.log('navigator.permissions.query')
            permissionStatus.onchange = () => {
                this.permissionStatus = permissionStatus.state
            };
        });
        
    },
    watch: {
        backendActive: function(){
            this.firebaseApp = initializeApp(this.FIREBASE_CONFIG)
            const messaging = getMessaging(this.firebaseApp)
            this.getTokenStarted = getToken(messaging, {vapidKey: "BGDBm44OnbP2bulOrUtnl8yeA_RZu7uE0EHOYUsW1pGvaiEHQetzvCuJB32yKLGr6Pzvibgma6fZi9hT3q92X94"}).then(
                (token) => {
                    // console.log('token', token)
                    if (token){
                        if (!utils.isDevelopment) this.sendToken(token)
                        if (utils.isDevelopment) console.log('token', token)
                        if (this.testPage) this.saveToken(token)
                    }
                }
            ).catch((e)=>{
                this.permissionStatus = 'denied'
            })
        },
        firebaseApp: function(){
            if (!this.FIREBASE_USERID) utils.safeGoal('firebaseInit', this)
        },
        FIREBASE_TOKEN: function(newVal, oldVal){
            if (!!newVal && !oldVal) utils.safeGoal('firebaseSubscribe', this)      
        },
        getTokenStarted: function(){
            // console.log('this.getTokenStarted', this.getTokenStarted)
            // Notification.requestPermission()
            if (this.permissionStatus === 'default') this.PUSH_PROMPT_FIRE()
        },
        permissionStatus: function(){
            this.PUSH_PROMPT_UNFIRE()
        },
        lockerTrigger: function(newVal, oldVal){
            if (newVal && !oldVal){
                const vueContext = this
                window.setTimeout(function(){
                    vueContext.modalReady = true
                }, 100)
            }else{
                this.modalReady = false
            }
        },
        modalReady: function(){
            // console.log('Watch modalReady', this.modalReady)
        }
    }
}
</script>