<template>
    <div>
        <GetContent v-on:contentGetted="arrayClone" v-bind:dataFeed="dataFeed"></GetContent>
        <b-alert 
            v-if="this.SCREEN_SIZE>1"
            show 
            class="app-ticker position-fixed rounded-0 py-2 m-0" 
            :style="tickerStyle"
            variant="primary"
        >
            <div class="app-marquee">
                <TickerBlockItem v-for="(item, index) in blockItems" 
                    v-bind:key="index"
                    v-bind:item="item"       
                    v-bind:goalName="goalName" 
                    v-bind:siteContent="siteContent"
                    v-bind:wopenTrigger="wopenTrigger"
                    v-on:itemClick="itemClick"  
                >
                1
                </TickerBlockItem>
            </div>
        </b-alert>
    </div>
</template>

<script>
import utils from '../../functions/functions'
import TickerBlockItem from '../integrations/TickerBlockItem.vue'
import { BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        BAlert,
        TickerBlockItem
    },
    data(){
        return {
            blockItems: [],
        }
    },
    props: ['limit', 'dataFeed', 'goalName', 'wopenTrigger'],
    computed: {
        ...mapGetters(['REFRESHING', 'PLATFORM', 'SCREEN_SIZE']),
        tickerStyle: function(){
            const styleBase = 'left: 0; right: 0; '
            const position = (this.PLATFORM === 'desktop') ? 'bottom: 0;' : 'top: 0'
            return styleBase+position
        },
        siteContent: function(){
            return (this.dataFeed === 'site') ? true : false
        }
    },
    methods: {
        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        },        
        arrayClone: function(arr){
            this.blockItems = []
            const shuffled = utils.shuffleArray(arr)
            const limit = (this.limit) ? this.limit : arr.length
            this.blockItems = shuffled.slice(0, limit)
        },
    },
    created: function(){
    },
}
</script>

<style lang="sass">
.app-ticker
    z-index: 4
@keyframes scroll-marquee
    to
        transform: translateX(-100%)
.app-marquee    
    overflow: hidden
    white-space: nowrap
    display: inline-block
    animation: scroll-marquee 2800s linear infinite
    &:hover
        animation-play-state: paused
</style>