<template>
    <aside>
        <b-link 
        :class="linkClass"
        :to="linkTo"
        :href="linkHref"
        :target="target"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
        @click="itemClick"
        >
            <b-aspect class="position-relative rounded-circle overflow-hidden d-flex justify-content-center align-items-center mr-3 flex-shrink-0" style="width: 100px; height: 100px;">
                <div class="position-absolute" style="top: 0; bottom: 0; left: 0; right: 0">
                    <b-skeleton width="100%" height="100%"></b-skeleton>
                </div>
                <figure class="rounded overflow-hidden d-flex justify-content-center align-items-center position-absolute">
                    <b-img-lazy :src="item.img"></b-img-lazy> 
                </figure>            
            </b-aspect>
            <span class="font-weight-bold">
                {{ item.title }}
            </span>
        </b-link>
        <hr v-if="borderLine" class="my-2">
    </aside>
</template>

<script>
import { BLink, BAspect, BSkeleton, BImgLazy } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import utils from '../../functions/functions'
export default {
    components: {
        BLink, 
        BAspect,
        BSkeleton,
        BImgLazy,
    },
    data(){
        return {
            itemHover: false,
        }
    },
    props: ['siteContent', 'item', 'index', 'goalName', 'wopenTrigger'],
    computed: {
        ...mapGetters(['PLATFORM', 'SCREEN_SIZE']),
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : utils.getLink(this.item.url, this)
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        imgWrapStyle: function(){
            return 'width: 100px; height: 100px;'
        },
        linkClass: function(){
            const classBase = 'd-flex align-items-center text-decoration-none text-uppercase w-100'
            const color = (this.itemHover) ? 'text-primary' : 'text-black'
            return `${classBase} ${color}`
        },
        borderLine: function(){
            return (this.index === 0 && this.SCREEN_SIZE <=2) ? true : false
        }
    },
    methods: {
        mouseEnter: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? true : this.itemHover
        },
        mouseLeave: function(){
            this.itemHover = (this.PLATFORM === 'desktop') ? false : this.itemHover
        }, 
        itemClick: function(){
            const vueContext = this
            utils.safeGoal(this.goalName, vueContext)
            utils.safeGoal('GoLink', vueContext)
        }       
    },
}
</script>

<style lang="sass" scoped>
figure
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0
img
    height: 100%
    width: auto    

@media (min-width: 768px)    
    aside
        width: calc(50% - 16px)
</style>