<template>
    <b-link
        :class="classLink" 
        :style="styleParent"
        :href="linkHref"
        :to="linkTo"
        :target="target"
        @click="itemClick"
    >
        <b-aspect :aspect="aspect" class="position-relative">
            <div class="positon-absolute rounded d-flex justify-content-center align-items-center overflow-hidden" style="top:0; bottom: 0; left: 0; right: 0;">
                <img :src="imageSrc" :style="imageStyle">
            </div>
        </b-aspect>
        <div class="position-absolute d-flex flex-column justify-content-end align-itemc-start pb-2 px-2" style="top: 0; left: 0; right: 0; bottom: 0;">
            <div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div v-if="landscapeImage" class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div v-if="landscapeImage" class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <!-- <div v-if="isSparrow" class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div>
                <div v-if="isSparrow" class="app-slide-overlay position-absolute" :style="styleColorOverlay"></div> -->
            </div>
            <div :class="classTitle" :style="styleTitle">
                {{ item.title }}
            </div>
            <div v-if="showSiteName" class="app-block-name position-relative align-self-start rounded-pill text-white px-3 py-0 bg-dark">
                {{ item.name }}
            </div>
            <b-badge v-if="showBadge" pill variant="success" class="position-relative align-self-start">Читать с рекламой</b-badge>
        </div>
    </b-link>
</template>


<script>
import utils from '../../functions/functions'
import {FastAverageColor} from 'fast-average-color'
import { BAspect, BLink, BBadge } from 'bootstrap-vue'
export default {
    components: {
        BAspect,
        BLink, 
        BBadge
    },
    data(){
        return{
            mainColorRgb: '255, 255, 255',
        }
    },
    props: ['item', 'aspect', 'siteContent', 'wopenTrigger'],
    computed: {
        classTitle: function(){
            const classBase = 'app-block-title position-relative mb-2 font-weight-bold '
            const textModificator = (this.mainColorRgb === '255, 255, 255') ? '' : 'text-white'
            return classBase+textModificator
        },   
        styleTitle: function(){
            return (this.mainColorRgb === '255, 255, 255') ? 'color: black; text-shadow: rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px, rgb(255, 255, 255) 0 0px 4px;' : 'text-shadow: 0 0 4px #000000;'
        },              
        imageSrc: function(){
            return this.item.img.replaceAll('100_100', '400_400')
        },
        styleColorOverlay: function(){
            const radialGradient = `background:radial-gradient(100% 100% at 50% 0px, transparent 50%, rgb(${this.mainColorRgb}) 100%) rgba(0, 0, 0, 0.2)`
            return (this.mainColorRgb) ? radialGradient : null
        },
        styleParent: function(){
            return `background:rgb(${this.mainColorRgb})`
        },
        classLink: function()                {
            const classBase = 'app-block position-relative rounded overflow-hidden flex-shrink-0 mb-3 '
            return (this.siteContent) ? classBase+'pb-5' : classBase+'pb-3'
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        linkHref: function(){
            return (this.siteContent) ? null : utils.getLink(this.item.url, this)
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        },
        landscapeImage: function(){
            return (this.siteContent || this.item.url.match(/smi2\./i) || this.item.img.match(/sparrow\./i))
        },
        isSparrow: function(){
            return (this.item.img.match(/sparrow\./i))
        },
        showSiteName: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) && this.item.name && !this.item.url.match(/smi2\./i))
        },
        showBadge: function(){
            const vueContext = this
            // return (utils.isRotator(vueContext) && this.item.url.match(/smi2\./i))
            return false
        },
        imageStyle: function(){
            return (this.item.img.match(/cvnqiv\.com/i)) ? 'position:absolute; top:0; bottom: 0; height: 100%; width: auto;' : null
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        }        
    },
    beforeMount: function(){
        if (this.item.rgb){
            this.mainColorRgb = this.item.rgb
        }else{
            const imgPath = (this.siteContent) ? this.item.imgMicro : this.item.img
            const fac = new FastAverageColor()
            fac.getColorAsync(imgPath)
                .then(color => {
                    const colorString = color.rgb
                    const match = colorString.match(/([\d]+),([\d]+),([\d]+)/i)
                    this.mainColorRgb = `${match[1]},${match[2]},${match[3]}`
                })
                .catch(e => {
                    console.log('color define brocken')
                    console.log(e);
                }
            )
        }
    },
}
</script>

<style lang="sass">
.app-block
    width: 100%
@media (min-width: 576px)
    .app-block
        width: calc(50% - 16px)
@media (min-width: 992px)     
    .app-block
        width: calc(33.3% - 16px)       
.app-block-name
    font-size: 0.7rem
    opacity: 0.5
.app-block-title    
    font-size: 1.24rem
    transition: all 0.2s
    // text-shadow: 0 0 4px #000000
</style>

<style lang="sass" scoped>
img
    width: 100%
    height: auto
</style>