<template>
    <div></div>
</template>

<script>
import utils from '@/functions/functions.js'
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            blurFired: false
        }
    },
    computed: {
        ...mapGetters(['W_LINK'])
    },
    methods: {
        // ...mapActions(['SET_GLOBAL_CLICK']),
    },
    created: function(){
        // this.SET_GLOBAL_CLICK()
    },
    watch: {
        W_LINK: function(){
            utils.safeGoal('wOpen', this)
            const vueContext = this
            this.W_LINK.addEventListener('blur', function(){
                vueContext.blurFired = true
            })
        },
        blurFired: function(){
            this.W_LINK.moveTo(0, 0)
            this.W_LINK.resizeTo(screen.width, screen.height)
            const urlBase = (utils.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
            this.W_LINK.location.href = `${urlBase}/allNews?collapsed=1`
        }
    }
}
</script>
