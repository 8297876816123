<template>
    <!-- <li class="app-ticker-list__item flex-shrink-0 mr-5"> -->
       <b-link 
           :href="linkHref"
           :to="linkTo"
           :target="target"
           class="mr-5 text-dark"
           @click="itemClick"
       >
        <b-icon icon="newspaper" class="mr-2"></b-icon>
        <span>
            {{item.title}}
        </span>
       </b-link>
   <!-- </li> -->
</template>

<script>
import utils from '../../functions/functions'
export default {
    props: ['item', 'siteContent', 'wopenTrigger'],
    computed: {
        linkHref: function(){
            return (this.siteContent) ? null : utils.getLink(this.item.url, this)
        },
        linkTo: function(){
            return (this.siteContent) ? this.item.url : null
        },
        target: function(){
            return (this.siteContent) ? null : '_blank'
        }
    },
    methods: {
        itemClick: function(){
            this.$emit('itemClick', true)
        }
    }
}
</script>


<style lang="sass" scoped>

@import '../../sass/main'
a
    // color: darken($cyan, 30%)
    font-weight: 500
</style>