<template>
    <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/functions/functions.js'
export default {
    data(){
        return {
            ref: document.referrer,
            opener: window.opener
        }
    },
    computed: {
        ...mapGetters(['STANDALONE', 'BROWSER', 'PLATFORM', 'INSTALLABILITY']),
        unexepredRef: function(){
            return ((this.ref && !utils.ref24Hard() && !utils.refInfoxHard() && !utils.refSmi2Hard() && !utils.refStopHard()) && !(document.referrer && document.referrer.match(/newsonline/i))) ? true : false
        },
        testPage: function(){
            return (utils.getIdFromAddressBar() === '384809')
        },
    },
    methods: {
        refLogger: async function(){
            if (!this.unexepredRef) return false
            utils.safeGoal('refLog', this)
            try {
                const res = await fetch(`${utils.backendHost}/refLogger`, {
                method: 'POST',
                headers: {
                    'Content-type' : 'application/json'
                },
                body: JSON.stringify({ref: this.ref}),
                })
            }catch(err){
                console.log('err', err)
            }
       },
        opLogger: function(){

        }
    },
    created: function(){
        if (this.STANDALONE) utils.safeGoal('Standalone', this)
        utils.safeGoal(((utils.fullScreenAbility) ? 'fullscreenEnable' : 'fullscreenDisable'), this)
        utils.safeGoal(((this.INSTALLABILITY) ? 'installabilityTrue' : 'installabilityFalse'), this)
        if (this.BROWSER === 'Chrome' && this.PLATFORM != 'desktop') utils.safeGoal('ChromeMobile', this)
        if (utils.smi24Ref()) utils.safeGoal('Ref24', this)
        if (utils.smi24UTM()) utils.safeGoal('UTM24', this)
        if (utils.hasReferrerHard()) utils.safeGoal('referrerHard', this)
        if (utils.fromPush)  utils.safeGoal('fromPush', this)
        if (utils.fromPush1)  utils.safeGoal('fromPush1', this)
        if (utils.fromPush2)  utils.safeGoal('fromPush2', this)
        if (utils.fromPush3)  utils.safeGoal('fromPush3', this)
        if (utils.fromPush4)  utils.safeGoal('fromPush4', this)
        if (utils.fromPush5)  utils.safeGoal('fromPush5', this)
        if (utils.fromPush6)  utils.safeGoal('fromPush6', this)
        if (utils.smi24Press()) utils.safeGoal('24Press', this)
        if (utils.smi24Info()) utils.safeGoal('24Info', this)
        // if (utils.refSpr()) utils.safeGoal('spr', this)
        // if (utils.hasOpener()) utils.safeGoal('hasOp', this)
        // if (utils.refSmi2() && !utils.hasOpener()) utils.safeGoal('str', this)
        if (utils.refSmi2()) utils.safeGoal('s', this)
        if (!utils.refInfoxHard() && !utils.refInfox() && !utils.ref24() && !utils.refSmi2()) utils.safeGoal('nR', this)
        // this.refLogger()
        if (!("localStorage" in window)) utils.safeGoal('noStorage', this)
    }
}
</script>
