export default {
    state: {
        adfLoaded: undefined,
        adfBlocks: {
            imageArea: undefined,
            contentArea1: undefined,
            contentArea2: undefined,
            contentArea3: undefined,
        }
    },
    actions: {
        ADF_LOADED(context){
            context.commit('ADF_UPDATE', true)
        },
        ADB_BLOCK_MOUNTED(context, blockName){
            context.commit('ADF_BLOCKS_UPDATE', blockName)
        }       
    },
    mutations: {
        ADF_UPDATE(state, val){
            state.adfLoaded = val
        },
        ADF_BLOCKS_UPDATE(state, blockName){
            state.adfBlocks[`${blockName}`] = true
        }
    },
    getters: {
        ADF(state){
            return state.adfLoaded
        },
        ADF_BLOCKS_READY(state){
            let res = true
            for (let key in state.adfBlocks){
                if (!state.adfBlocks[`${key}`]){
                    res = false
                    break
                }
            }
            return res
        }
    }
}