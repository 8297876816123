<template>
    <div>TransferView</div>
</template>

<script>
import utils from '../functions/functions.js'
export default {
    computed: {
        transferTo: function(){
            const matches = document.location.search.match(/link=(.+)/i)
            return (matches && matches[1]) ? decodeURIComponent(matches[1]) : null
        }
    },

    created: function(){
        console.log(`transferTo ${this.transferTo}`)
        if (!utils.isDevelopment) document.location.href = this.transferTo
        // console.log(`decodeURI ${decodeURI(this.transferTo)}`)
        // console.log(`encodeURI ${encodeURI(this.transferTo)}`)
    }

}
</script>
