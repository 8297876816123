import utils  from "../functions/functions"
export default {
    state: { 
        post: {
            title: '',
            img: '',
            text: '',
            url: '',
        },
        posts: [],
        postsLocked: [],
        nextPostList: [],
        prefetched: {
            list: false,
            post: false,
            imgMedium: false,
            imgFull: false,
            imgMicro: false
        },
        textState: false,
        postIsExternal: false
    },
    actions: {
        FAKE_POSTS_RESET(context){
            context.commit('FAKE_POSTS_UPDATE')
        },
        TEXT_STATE_SET(context, val){
            context.commit('TEXT_STATE_UPDATE', val)
        },
        REQUEST_PREFETCHED(context, requestType){
            context.commit('PREFETCHER_UPDATE', requestType)
        },
        //, cacheMode = 'default'
        async FETCH_POST(context, id) {
            if (!id){
                utils.consoleLog('no post id')
                return false
            }
            try {
                const url = utils.restAPIpost(id)
                // console.log('url', url)
                const res = await fetch(url, {
                    //https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
                    cache: 'force-cache', //cacheMode,
                    credentials: 'omit',
                })
                const post = await res.json()
                post.img = (process.env.VUE_APP_LOCAL_RESOURCES === '1') ? `http://localhost/content/mock/image/?full=${Date.now()}` : post.img
                context.commit('UPDATE_POST', post)                
            }catch(err){
                console.log('err', err)
            }

        },
        SET_POST(context, post){
            context.commit('UPDATE_POST', post)
        },
        RESET_POST(context){
            const post = {
                title: '',
                image: '',
                text: ''
            }
            context.commit('UPDATE_POST', post)
        },
        //cacheMode = 'default'
        async FETCH_LIST(context, feedName = 'main'){
            const validePosts = []
            try {
                const url = utils.restAPIlist(feedName)
                const res = await fetch(url, {
                    //https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
                    // cache: cacheMode, //это пока не надо
                    // credentials: 'omit',  
                    // mode: 'no-cors'                  
                    mode: 'cors'
                   })
                //const postArray = (res.ok) ? await res.json() : []
                if (res.ok){
                    const postArray = await res.json() //
                    for (let i=0; i<postArray.length; i++){
                        if (postArray[i].img){
                            validePosts.push(postArray[i])
                        }
                    }                    
                }
            }catch(e){
                utils.consoleLog('error', e)
            }
            
            if (feedName === 'exclusive_full'){
                context.commit('UPDATE_NEXT_POST_LIST', validePosts)
            }else{
                context.commit('UPDATE_LIST', validePosts)
            }
        }
    },
    mutations: {
        FAKE_POSTS_UPDATE(state){
            state.postsLocked = state.posts
            state.posts = []
            const updateTime = utils.randomInt(300, 1500)
            window.setTimeout(function(){
                state.posts = state.postsLocked
            }, updateTime)
        },
        TEXT_STATE_UPDATE(state, val){
            state.textState = val
        },
        PREFETCHER_UPDATE(state, requestType){
            state.prefetched[requestType] = true
        },
        UPDATE_POST(state, post) {
            state.post = post
        },
        UPDATE_LIST(state, posts){
            state.posts = posts
            state.postsLocked = posts ////// ??????????????????????????????????????????
        },
        UPDATE_NEXT_POST_LIST(state, posts){
            state.nextPostList = posts
        }
    },
    getters: {
        IS_EXTERNAL(state){
            return state.postIsExternal
        },
        FULLTEXT(state){
            return state.textState
        },
        PREFETCHED_LIST(state){
            return state.prefetched.list
        },
        PREFETCHED_CONTENT(state){
            return state.prefetched.post
        },
        PREFETCHED_MEDIUM(state){
            return state.prefetched.imgMedium
        },
        PREFETCHED_FULL(state){
            return state.prefetched.imgFull
        },
        PREFETCHED_MICRO(state){
            return state.prefetched.imgMicro
        },                
        POST(state) {
            return state.post
        },
        POSTS(state){
            return state.posts
        },
        NEXT_POST_LIST(state){
            return state.nextPostList
        },
        POST_LIST_BY_ID(state){
            let newCollection = {}
            for (let i=0; i<state.postsLocked.length; i++){
                const newItemName = state.postsLocked[i].id
                newCollection[`${newItemName}`] = state.postsLocked[i] 
            }
            return newCollection
        }
    },    
}