<template>
    <div v-if="showOverlay" id="desktop-overlay">
        <div></div>
        <div></div>
        <!-- <div></div> -->
        <figure :class="figureClass" id="app-overlay-animation"></figure>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/functions/functions.js'
export default {
    data(){
        return {
            cdn1: '/vantajs/p5.min.js',
            cdn2: '/vantajs/vanta.trunk.min.js',
            loadCounter: 0,
            showOverlay: true,
        }
    },
    computed: {
        ...mapGetters(['BROWSER']),
        figureClass: function(){
            const classBase = 'position-absolute'
            let browserType = 'app-overlay-chrome'
            if (this.BROWSER.match(/Ya/i)) browserType = 'app-overlay-yandex'
            if (this.BROWSER.match(/Edge/i)) browserType = 'app-overlay-edge'
            return `${classBase} ${browserType}`
        },
        lineColor: function(){
            let color = '539845' // '34a853'
            if (this.BROWSER.match(/Ya/i)) color = '645021'
            // if (this.BROWSER.match(/Edge/i)) browserType = 'app-overlay-edge'
            return `0x${color}`
        }
    },
    methods: {
        hideOverlay: function(){
            utils.safeGoal('hideOverlay', this)
            this.showOverlay = false
        },
        counterIncrement: function(){
            this.loadCounter = this.loadCounter + 1
        },
        cdnRequire: function(){
            const vueContext = this
            const a = document.createElement('script')
            a.src = this.cdn1
            a.onload = function(){
                vueContext.counterIncrement()
            }
            const b = document.createElement('script')
            b.src = this.cdn2
            b.onload = function(){
                vueContext.counterIncrement()
            }
            document.head.appendChild(a)
            document.head.appendChild(b)
        },
        animationRender: function(){
            const vueContext = this
            window.VANTA.TRUNK({
                el: "#app-overlay-animation",
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 400.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                // color: '0x539845', //vueContext.lineColor,//0x645021,
                // color: 0x645021,
                color: 0x539845,
                //backgroundColor: 0xf0214,
                backgroundColor: 'transparent',
                // backgroundColor: 'blue',
                // spacing: 4.50,
                chaos: 10.00
            })
        }
    },
    created: function(){
        // this.cdnRequire()
    },
    mounted: function(){
        const vueContext = this
        window.setTimeout(function(){
            vueContext.cdnRequire()
        }, 300)
        // this.cdnRequire()
    },
    watch: {
        loadCounter: function(){
            console.log('this.loadCounter', this.loadCounter)
            if (this.loadCounter === 2){
                this.animationRender()
            }
        }
    }
}
</script>



<style lang="sass" scoped>
div
    // animation-name: background-fade
    // animation-duration: 1s
    // animation-timing-function: ease-in-out
    background: linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.94) 100%)
    // background-color: rgba(0, 0, 0, 0.94)
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    z-index: 1101
figure
    z-index: 1101
    left: 0
    // top: -100vh
    // right: -100vw
    bottom: 0    
    margin: 0
.app-overlay-yandex
    top: -100vh
    right: -100vw
.app-overlay-chrome
    top: -60vh
    right: 0
</style>